import React, {useState} from 'react';
import './App.css';
import {Amplify} from 'aws-amplify';
import {withAuthenticator} from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import {loader as transactionsLoader, Transactions} from './Transactions'
import {Bills, loader as billsLoader} from './Bills'
import {Budgets, loader as budgetsLoader} from './Budgets'
import {createBrowserRouter, createRoutesFromElements, NavLink, Outlet, Route, RouterProvider,} from "react-router-dom";
import {Institutions, loader as institutionsLoader} from "./Institutions";
import {AddPlaidItem, FixPlaidItem, loader as plaidItemLoader} from "./AddPlaidItem";

Amplify.configure({
    ...awsconfig,
    'aws_appsync_graphqlEndpoint': 'https://api.reckonloid.com/graphql',
    'aws_appsync_region': 'us-east-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
});

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root/>}>
            <Route path="budgets" element={<Budgets/>} loader={budgetsLoader}/>
            <Route path="bills" element={<Bills/>} loader={billsLoader}/>
            <Route path="institutions" element={<Institutions/>} loader={institutionsLoader}/>
            <Route path="addplaiditem" element={<AddPlaidItem/>} loader={plaidItemLoader}/>
            <Route path="fixitem/:id" element={<FixPlaidItem/>} loader={plaidItemLoader}/>
            <Route path="" exact element={<Transactions/>} loader={transactionsLoader}/>
        </Route>
    )
);

function Root() {
    const [uiState, setUIState] = useState({
        BillsTabState: {
            expandedBillIds: [],
            selectedAccount: null
        },
        TransactionsTabState: {
            page: 1,
            startPositions: [null],
            selectedAccount: null
        }
    });

    return (
        <div>
            <nav>
                <ul>
                    <li><NavLink to="/" end>Transactions</NavLink></li>
                    <li><NavLink to="/bills" end>Bills</NavLink></li>
                    <li><NavLink to="/budgets" end>Budgets</NavLink></li>
                    <li><NavLink to="/institutions" end>Institutions</NavLink></li>
                </ul>
            </nav>
            <div id="detail">
                <Outlet context={[uiState, setUIState]}/>
            </div>
        </div>
    );
}

function App({signOut}) {
    return (
        <RouterProvider router={router}/>
    );
}

export default withAuthenticator(App);