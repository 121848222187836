import {addBudget, hideBudget} from './graphql/mutations'
import {generateClient} from 'aws-amplify/api';
import {useLoaderData, useNavigation} from "react-router-dom";
import {useState} from "react";

const client = generateClient();

const GET_BUDGETS = `
    {
        myTeam {
            id
            name
            budgets {
                id
                label
                hidden
            }
        }
    }
`

export async function loader() {
    const result = await client.graphql({query: GET_BUDGETS});
    return result.data
}

const Budget = (props) => {
    const {label, budgetId, teamId, setBudgets} = props
    return (
        <span>{label}
            <button onClick={async () => {
                const { data } = await client.graphql({query: hideBudget, variables: {budgetId, teamId}});
                setBudgets((budgets) => budgets.map((budget) => (
                    budget.id === data.hideBudget.id ? {...budget, hidden: true} : budget
                )))
            }} className={"App-link small"}>Hide</button></span>
    )
}

const AddBudgetForm = (props) => {
    const {teamId, setBudgets} = props
    return (
        <form onSubmit={async e => {
            e.preventDefault();
            const input = e.target.elements["label"];
            const { data } = await client.graphql({
                query: addBudget,
                variables: {budgetLabel: input.value, teamId: teamId}
            })
            input.value = ""
            setBudgets((budgets) => [...budgets, data.addBudget])
        }}>
            <input type="text" name="label"/>
            <input type="submit" value="Add Budget"/>
        </form>
    )
}

export function Budgets() {
    const {myTeam} = useLoaderData();
    const navigation = useNavigation();
    const [budgets, setBudgets] = useState(myTeam.budgets);
    return navigation === "loading" ? <p>Loading...</p> :
        <section className={"budgets-container"}>
            {myTeam.budgets.length > 0 ? (<div>
                <h1>Budgets</h1>
                <ul className="budgets">
                    {budgets.filter((budget) => (!budget.hidden)).map(budget => {
                        return (
                            <li key={budget.id}><Budget
                                label={budget.label}
                                budgetId={budget.id}
                                teamId={myTeam.id}
                                setBudgets={setBudgets}
                            /></li>
                        )
                    })}
                </ul>
            </div>) : (<div>No budgets</div>)}
            <AddBudgetForm teamId={myTeam.id} setBudgets={setBudgets}/>
        </section>
}
