import React from 'react'

export const AccountSelector = (props) => (
    <select name={props.name} value={props.selectedAccount === null ? "all" : props.selectedAccount}
            className={props.className}
            onChange={(e) => {
                e.preventDefault();
                const value = e.target.value === "all" ? null : e.target.value;
                props.onAccountChange(value)
            }}
    >
        {props.omitAll ? null : (<option value={"all"}>All</option>)}
        {props.accounts.map((account => (
            <option key={account.plaidAccountId} value={account.plaidAccountId}>{account.label}</option>
        )))}
    </select>
)
