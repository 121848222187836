/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addBudget = /* GraphQL */ `
  mutation AddBudget($budgetLabel: String!, $teamId: ID!) {
    addBudget(budgetLabel: $budgetLabel, teamId: $teamId) {
      id
      label
      hidden
      __typename
    }
  }
`;
export const hideBudget = /* GraphQL */ `
  mutation HideBudget($budgetId: ID!, $teamId: ID!) {
    hideBudget(budgetId: $budgetId, teamId: $teamId) {
      id
      label
      hidden
      __typename
    }
  }
`;
export const assignToBudget = /* GraphQL */ `
  mutation AssignToBudget($transactionId: ID!, $budgetId: ID) {
    assignToBudget(transactionId: $transactionId, budgetId: $budgetId) {
      id
      teamId
      transactionId
      payload {
        budgetId
        __typename
      }
      __typename
    }
  }
`;
export const revalidatePlaidItem = /* GraphQL */ `
  mutation RevalidatePlaidItem($plaidItemId: ID!, $teamId: ID!) {
    revalidatePlaidItem(plaidItemId: $plaidItemId, teamId: $teamId)
  }
`;
export const createBill = /* GraphQL */ `
  mutation CreateBill($bill: BillInput!, $teamID: ID!) {
    createBill(bill: $bill, teamID: $teamID) {
      id
      accountId
      startDate
      endDate
      __typename
    }
  }
`;
export const updateBill = /* GraphQL */ `
  mutation UpdateBill($billId: ID!, $bill: BillInput!) {
    updateBill(billId: $billId, bill: $bill) {
      id
      accountId
      startDate
      endDate
      __typename
    }
  }
`;
