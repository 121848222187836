import {usePlaidLink} from 'react-plaid-link';
import {generateClient} from "aws-amplify/api";
import {useLoaderData, useParams} from "react-router-dom";
import {revalidatePlaidItem} from "./graphql/mutations";

const client = generateClient();

// ...

const GET_PLAID_LINK_TOKEN = `
    query plaidLinkToken($plaidItemId: ID){
        myTeam {
            id
            plaidLinkToken(plaidItemId: $plaidItemId)
        }
    }
`

export async function loader({params}) {
    const {data} = await client.graphql({query: GET_PLAID_LINK_TOKEN, variables: {plaidItemId: params.id || ""}});
    return data.myTeam;
}

export function AddPlaidItem() {
    const myTeam = useLoaderData();
    const {open, ready} = usePlaidLink({
        token: myTeam.plaidLinkToken,
        onSuccess: (public_token, metadata) => {
            console.log(public_token);
            console.log(metadata);
        },
    });

    return (
        <button onClick={() => open()} disabled={!ready}>
            Connect a bank account
        </button>
    );
}

export function FixPlaidItem() {
    const myTeam = useLoaderData();
    let {id} = useParams();
    const {open, ready} = usePlaidLink({
        token: myTeam.plaidLinkToken,
        onSuccess: async (public_token, metadata) => {
            await client.graphql({query: revalidatePlaidItem, variables: {plaidItemId: id, teamId: myTeam.id}});
            window.close();
        },
    });

    return (
        <button onClick={() => open()} disabled={!ready}>
            Fix this bank account
        </button>
    );
}