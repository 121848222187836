import { generateClient } from 'aws-amplify/api';
import {
    Link,
    useLoaderData,
    useNavigation,
} from "react-router-dom";
const client = generateClient();

const GET_INSTITUTIONS = `
    {
        myTeam {
            id
            plaidItems {
                id
                institutionName
                invalid
            }
        }
    }
`


export async function loader() {
    const result = await client.graphql({query: GET_INSTITUTIONS});
    return result.data
}

const PlaidItem = (props) => (
    <span className={props.item.invalid ? "invalid" : ""}>
        {props.item.institutionName}&nbsp;
        {props.item.invalid ? (<Link to={"/fixitem/" + props.item.id} target={"fixitem"}>fix</Link>) : null}
    </span>
)

export function Institutions() {
    const { myTeam } = useLoaderData();
    const navigation = useNavigation();
    const body = (navigation === "loading") ?
        <p>Loading...</p> :
        <ul>
            {myTeam.plaidItems.map((plaidItem) => (
                <li key={plaidItem.id}><PlaidItem item={plaidItem}/></li>)
            )}
        </ul>;

    return (
        <section className={"institutions-container"}>
            <h1>Institutions</h1>
            {body}
        </section>
    );
}
