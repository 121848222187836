import React, {useEffect, useState} from 'react';
import {AccountSelector} from "./Accounts";
import {createBill} from './graphql/mutations'
import {generateClient} from 'aws-amplify/api';
import {useLoaderData, useNavigation, useOutletContext} from "react-router-dom";

const client = generateClient();

const GET_BILLS = `
    {
        myTeam {
            id
            trackedAccounts {
                label
                plaidAccountId
            }
            bills {
                id
                accountId
                startDate
                endDate
            }
        }
    }
`

const GET_SPENDING_SUMMARY = `
    query GetSpendingSummary($billId: ID!) {
        myTeam {
            id
            spendingSummary(billId: $billId) {
                id
                total
                items {
                    id
                    budgetId
                    subtotal
                }
            }
            budgets {
                id
                label
            }
        }
    }
`

export async function loader() {
    const result = await client.graphql({query: GET_BILLS});
    return result.data
}

const AddBillForm = (props) => (
    <div>
        <form className={"bill-heading"} onSubmit={(e) => {
            e.preventDefault()
            const from = e.target.elements["fromDate"].value;
            const to = e.target.elements["toDate"].value;
            const accountId = e.target.elements["accountId"].value;
            props.onSubmit(from, to, accountId)
        }}>
            <AccountSelector
                name="accountId"
                className={"account-name"}
                omitAll={true}
                accounts={props.accounts}
                selectedAccount={props.selectedAccount}
                onAccountChange={props.onAccountChange}
            />
            <div className={"dates"}>
                <input type="text" name="fromDate"/> -
                <input type="text" name="toDate"/>
            </div>
            <div className={"controls"}><input type="submit" value={"Add"}/></div>
        </form>
    </div>
)

const formatDate = (dateStr) => {
    const parts = dateStr.split('-')
    const date = new Date(parts[0], parts[1] - 1, parts[2])
    const dtf = new Intl.DateTimeFormat('en', {year: 'numeric', month: 'short', day: '2-digit'})
    const [{value: mo}, , {value: da}, , {value: ye}] = dtf.formatToParts(date)
    return `${mo} ${da}, ${ye}`
}

function Bill(props) {
    const [myTeam, setMyTeam] = useState(null)
    useEffect(() => {
            if (props.expanded) {
                client.graphql({query: GET_SPENDING_SUMMARY, variables: {billId: props.bill.id}}).then(({data}) => {
                    setMyTeam(data.myTeam)
                });
            }
        }, [props.expanded, props.bill.id]
    );
    return (
        <div>
            <div className={"bill-heading"}>
                <button
                    className={"expand App-link small"}
                    onClick={props.toggleExpansion}
                >{props.expanded ? "collapse" : "expand"}</button>
                <div className={"account-name"}>{
                    props.trackedAccounts.filter((account) => (
                        account.plaidAccountId === props.bill.accountId
                    ))[0].label
                }</div>
                <div className={"dates"}>
                    {formatDate(props.bill.startDate)} - {formatDate(props.bill.endDate)}
                </div>
            </div>
            {props.expanded ? (
                !myTeam ? <p>Loading...</p> :
                    <div className={"bill-expansion"}>
                        {myTeam.spendingSummary.items.map((item) => (
                            <div className={"spending-summary-item"} key={item.id}>
                                <div className={"budget"}>
                                    {item.budgetId === null ? "Uncategorized" :
                                        myTeam.budgets.filter((budget) => (
                                            budget.id === item.budgetId
                                        ))[0].label
                                    }
                                </div>
                                <div className={"amount"}>
                                    {item.subtotal.toFixed(2)}
                                </div>
                            </div>
                        ))}
                        <div className={"spending-summary-item total"}>
                            <div className={"amount"}>{myTeam.spendingSummary.total}</div>
                        </div>
                    </div>
            ) : null}
        </div>
    )
}

export function Bills() {
    const [uiState, setUIState] = useOutletContext();

    const {expandedBillIds, selectedAccount} = uiState.BillsTabState;
    const toggleExpansion = (billId) => {
        setUIState((uiState) => ({
            ...uiState,
            BillsTabState: {
                ...uiState.BillsTabState,
                expandedBillIds: expandedBillIds.includes(billId) ?
                    expandedBillIds.filter((id) => (id !== billId)) :
                    expandedBillIds.concat(billId)
            }
        }))
    }
    const setSelectedAccount = (accountId) => {
        setUIState((uiState) => ({
            ...uiState,
            BillsTabState: {
                ...uiState.BillsTabState,
                selectedAccount: accountId
            }
        }))
    }
    const {myTeam} = useLoaderData();
    const navigation = useNavigation();
    const [bills, setBills] = useState(myTeam.bills);

    return (
        navigation === "loading" ? <p>Loading...</p> :
            <div className={"bills-container"}>
                {bills.length === 0 ? null :
                    bills.map((bill) => (
                        <Bill
                            key={bill.id}
                            bill={bill}
                            trackedAccounts={myTeam.trackedAccounts}
                            expanded={expandedBillIds.includes(bill.id)}
                            toggleExpansion={() => {
                                toggleExpansion(bill.id)
                            }}
                        />
                    ))}
                <AddBillForm accounts={myTeam.trackedAccounts}
                             selectedAccount={selectedAccount}
                             onSubmit={async (from, to, accountId) => {
                                 const {data} = await client.graphql({
                                     query: createBill,
                                     variables: {
                                         bill: {
                                             accountId: accountId,
                                             startDate: from,
                                             endDate: to
                                         },
                                         teamID: myTeam.id
                                     }
                                 });
                                 setBills((bills) => bills.concat(data.createBill))
                             }}
                             onAccountChange={setSelectedAccount}
                />
            </div>
    )
}
