import React, {useEffect, useState} from 'react'
import {useLoaderData, useNavigation, useOutletContext} from "react-router-dom";
import {assignToBudget} from './graphql/mutations'
import {AccountSelector} from "./Accounts"
import './App.css'
import {generateClient} from "aws-amplify/api";

const client = generateClient();

const GET_BUDGETS_AND_TRACKED_ACCOUNTS = `
    {
        myTeam {
            id
            trackedAccounts {
                plaidAccountId
                label
            }
            budgets {
                id
                label
                hidden
            }

        }
    }
`

const GET_TRANSACTIONS = `
    query GetTransactions($startPosition: StartPositionInput, $filter: TransactionFilterInput)
    {
        myTeam {
            id
            transactions(startPosition: $startPosition, filter: $filter) {
                transactions {
                    id
                    accountId
                    budgetId
                    date
                    amount
                    pending
                    name
                }
                nextPageStartPosition {
                    id
                    date
                }
            }
        }
    }
`


export async function loader() {
    const result = await client.graphql({query: GET_BUDGETS_AND_TRACKED_ACCOUNTS});
    return result.data
}

const BudgetButton = (props) => {
    const abbreviate = (label) => (
        label.slice(0, 3)
    )
    let classes = "packed-button budget-button"
    if (props.hidden) {
        classes += " hidden"
    } else if (props.selected) {
        classes += " selected"
    }
    const budgetId = props.budget === null ? null : props.budget.id

    return (
        <div className={classes} onClick={() => {
            props.assignToBudget({
                variables: {
                    transactionId: props.transactionId,
                    budgetId: budgetId
                }
            })
        }}>
            {props.budget === null ? "X" : abbreviate(props.budget.label)}
        </div>
    )
}

const BudgetLists = (props) => (
    <div className={"budget-button-list"}>
        <BudgetButton
            transactionId={props.transactionId}
            teamId={props.teamId}
            budget={null}
            selected={!props.selected}
            hidden={props.hidden}
            assignToBudget={props.assignToBudget}
        />
        {props.budgets.sort((b1, b2) => b1.label.localeCompare(b2.label)).map((budget) => (
            <BudgetButton
                transactionId={props.transactionId}
                teamId={props.teamId}
                budget={budget}
                selected={props.selected === budget.id}
                hidden={props.hidden}
                key={budget.id}
                assignToBudget={props.assignToBudget}
            />
        ))}
    </div>
)

const Filters = (props) => (
    <AccountSelector
        accounts={props.accounts}
        selectedAccount={props.selectedAccount}
        onAccountChange={props.onAccountChange}
    />
)

const Paginator = (props) => (
    <div className={"paginator"}>
        {props.currentPage <= 1 ? "Prev" :
            <button className={"App-link"} onClick={props.prevPage}>Prev</button>}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Page {props.currentPage}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {props.lastPage ? "Next" :
            <button className={"App-link"} onClick={props.nextPage}>Next</button>}
    </div>
)

const mergePlaceholders = (transactions) => {
    const placeholderTransaction = {
        date: "\xa0",
        accountId: "",
        name: "\xa0",
        pending: false,
        amount: null
    };

    if (transactions.length === 25) {
        return transactions;
    }
    transactions = [...transactions]
    for (let i = transactions.length; i < 25; i++) {
        transactions.push({
            ...placeholderTransaction,
            id: `placeholder${i}`
        })
    }
    return transactions
}

const TransactionsTable = (props) => {
    const {startPosition, selectedAccount, page, setStartPosition} = props;
    const placeholderAccount = {plaidAccountId: '', label: '\xa0'};
    const [team, setTeam] = useState({teamId: "", transactions: mergePlaceholders([])})
    useEffect(() => {
        client.graphql({
            query: GET_TRANSACTIONS, variables: {
                startPosition: startPosition,
                filter: {
                    accountId: selectedAccount
                }
            }
        }).then(({data}) => {
            setTeam({teamId: data.myTeam.id, transactions: mergePlaceholders(data.myTeam.transactions.transactions)})
            if (data.myTeam.transactions.nextPageStartPosition) {
                setStartPosition(page + 1, data.myTeam.transactions.nextPageStartPosition)
            }
        });
    }, [startPosition, selectedAccount, page, setStartPosition]);
    const trackedAccounts = props.trackedAccounts.concat(placeholderAccount);
    const {teamId, transactions} = team;
    const loading = teamId === "";
    const assign = async (options) => {
        const {data} = await client.graphql({...options, query: assignToBudget});
        setTeam((team) => ({
            ...team,
            transactions: team.transactions.map((transaction) => (
                transaction.id === data.assignToBudget.transactionId ?
                    {...transaction, budgetId: data.assignToBudget.payload.budgetId} : transaction
            ))
        }));
    }

    return (
        <div>
            <div className={"xact-table-head"}>
                <Filters className={"filters"}
                         accounts={props.trackedAccounts}
                         selectedAccount={props.selectedAccount}
                         onAccountChange={props.setSelectedAccount}
                />
                <Paginator currentPage={props.page}
                           lastPage={props.lastPage}
                           nextPage={props.nextPage}
                           prevPage={props.prevPage}
                />
            </div>
            <table className={"xact-table"}>
                <thead>
                <tr>
                    <th className={"date"}>Date</th>
                    <th className={"account"}>Account</th>
                    <th className={"description"}>Description</th>
                    <th className={"amount"}>Amount</th>
                    <th className={"budget"}>Budget</th>
                </tr>
                </thead>
                <tbody>
                {transactions.map(transaction => (
                    <tr key={transaction.id}>
                        <td className={"date"}>{transaction.date}</td>
                        <td className={"account"}>{
                            trackedAccounts.find(
                                (act) => (act.plaidAccountId === transaction.accountId)
                            ).label}
                        </td>
                        <td className={"description"}>{transaction.name}
                            {transaction.pending ? " (pending)" : ""}</td>
                        <td className={"amount"}>{transaction.amount === null ? "" : transaction.amount.toFixed(2)}</td>
                        <td className={"budget"}>
                            <BudgetLists transactionId={transaction.id}
                                         selected={transaction.budgetId}
                                         budgets={props.budgets}
                                         teamId={teamId}
                                         hidden={loading || transaction.amount === null}
                                         assignToBudget={assign}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <Paginator currentPage={props.page}
                       lastPage={props.lastPage}
                       nextPage={props.nextPage}
                       prevPage={props.prevPage}
            />
        </div>
    )
}

export const Transactions = () => {
    const [uiState, setUIState] = useOutletContext();
    const {TransactionsTabState: transactionsTabState} = uiState;
    const {page, startPositions, selectedAccount} = transactionsTabState
    const navigation = useNavigation();
    const {myTeam} = useLoaderData();

    const nextPage = () => {
        setUIState((uiState) => ({
            ...uiState,
            TransactionsTabState: {
                ...uiState.TransactionsTabState,
                page: page + 1
            }
        }))
    }

    const prevPage = () => {
        setUIState((uiState) => ({
            ...uiState,
            TransactionsTabState: {
                ...uiState.TransactionsTabState,
                page: page - 1
            }
        }))
    }

    const setSelectedAccount = (accountId) => {
        setUIState((uiState) => ({
            ...uiState,
            TransactionsTabState: {
                ...uiState.TransactionsTabState,
                page: 1,
                selectedAccount: accountId,
                startPositions: [null]
            }
        }))
    }

    const setStartPosition = (page, startPosition) => {
        const newStartPositions = [...startPositions]
        const startPositionInput = {
            date: startPosition.date,
            id: startPosition.id
        }
        while (page > newStartPositions.length) {
            newStartPositions.push(startPositionInput)
        }
        newStartPositions[page - 1] = startPositionInput

        setUIState((uiState) => ({
            ...uiState,
            TransactionsTabState: {
                ...uiState.TransactionsTabState,
                startPositions: newStartPositions
            }
        }))
    }

    return (
        <section className={"xact-container"}>
            <h1>Transactions</h1>
            {navigation === "loading" ? <p>Loading...</p> :
                <TransactionsTable
                    nextPage={nextPage}
                    prevPage={prevPage}
                    lastPage={page >= startPositions.length}
                    startPosition={startPositions[page - 1]}
                    setSelectedAccount={setSelectedAccount}
                    setStartPosition={setStartPosition}
                    page={page}
                    selectedAccount={selectedAccount}
                    budgets={myTeam.budgets.filter((budget) => (!budget.hidden))}
                    trackedAccounts={myTeam.trackedAccounts}
                    transactions={myTeam.transactions}
                />}
        </section>
    )
}
